/* Styling for the checkbox in the student */
#myFileInput{
    display: none;
}

[type=checkbox] {
    width: 1.8rem;
    height: 1.8rem;
    color: #3B82F6;
    vertical-align: middle;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #FFFFFF;
    cursor: pointer;
}
  
[type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

[type=checkbox]::-moz-focus-outer{
  outline: 0;
}
