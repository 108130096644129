.react-datepicker__time-list-item--disabled{
    display: none;
}

.container {  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: minmax(30px, auto) minmax(30px, auto) minmax(30px, auto) minmax(30px, auto) minmax(30px, auto) minmax(30px, auto);
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "gDescription gDescription gDescription gDescription gDescription gDescription gDescription gDescription"
      "gIconLoc gLocation gLocation gLocation gLocation gLocation gLocation gLocation"
      "gIcon gStart gStart gStart gEnd gEnd gEnd gEnd"
      "gIconP gPresenter gPresenter gPresenter gPresenter gPresenter gPresenter gPresenter"
      "gIcon2 gColor gColor gColor gColor gColor gColor gColor"
      ". . . . . gSave gSave gSave";
}
  
.gDescription { grid-area: gDescription; }
  
.gIconLoc { grid-area: gIconLoc; }
  
.gLocation { grid-area: gLocation; }
  
.gIcon { grid-area: gIcon; }
  
.gStart { grid-area: gStart; }
  
.gEnd { grid-area: gEnd; }
  
.gIconP { grid-area: gIconP; }
  
.gPresenter { grid-area: gPresenter; }
  
.gIcon2 { grid-area: gIcon2; }
  
.gColor { grid-area: gColor; }
  
.gSave { grid-area: gSave; }
