.link {
    display:inline; 
    padding:10px; 
}

.link a {
    text-size-adjust: 10px;
} 

a:hover {
    color:purple;
    text-decoration: underline;
}