.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: white; /* You can change this to your preferred background color */
    width: 100%;
  }


.not-full-screen{
    width: 90%;
}

.fullscreen-button {
    position: fixed;
    bottom: 5px;
    right: 5px;
    color: rgb(0, 119, 0);
    background-color: white;
    border-radius: 15%;
    border: 2px solid rgb(194, 194, 194);
}


.max-icon{
    height: 2em;
    width: 2em;
    border-radius: 15%;
}

.max-icon:hover{
    background-color: rgb(0, 119, 0);
    color: white;
    transition: 0.2s;
}

.min-icon{
    height: 2em;
    width: 2em;
    border-radius: 15%;
}

.min-icon:hover{
    background-color: rgb(0, 119, 0);
    color: white;
    transition: 0.2s;
}


/* Increase font size for the entire calendar */
.rbc-calendar {
    font-size: 18px; /* default 16 */
}

/* Increase font size specifically for the events */
.rbc-event {
    font-size: 18px; /* default 16 */
    
}

/* Increase font size for the header (dates in month view, etc.) */
.rbc-header {
    font-size: 16px; /* default 14.4 */
}