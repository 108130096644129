.nav {
    display:flex;
    padding: 0px 20px;
}
/*
.home li{
    font-size:19px;
    padding: 2px 2px;
    float: left;
    height: 2.5em;
    color:rgb(255, 255, 255);
    font-weight: 500;
}
.custom li{
    margin: 0px 20px; 
    font-size:19px; 
    height: 2.5em;
    color:rgb(255, 255, 255); 
    text-decoration:none;
    font-weight: 500;
    float: left;
    list-style-type:none;
}

.manage li{
    float: right;
    font-size:19px; 
    height: 2.5em;
    color:rgb(255, 255, 255); 
    font-weight: 500;

}
*/
li button {
    font-size:19px; 
    display: inline-block;
    color: white;
    text-align: center;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: 500;
}

li a:hover {
    background-color:rgb(14, 88, 14);
}