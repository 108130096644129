@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing : border-box;
}

.nav {
  background-color: darkgreen;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0.5rem;

}

.site-title {
  font-size: 2rem;
}

.nav ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  cursor: pointer;

}
.nav a{
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  cursor: pointer;
}

.nav li.active{
  background-color:darkcyan;
}

.nav li:hover {
  background-color:green;
}

.form {
  padding:0.5rem;
  width: auto;
}

.drop-down {
  align-items: left;
  margin: dense;
}
